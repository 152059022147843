export const useGeoQuery = () => {
  const skGeoQuery = <T extends string, D extends number>(queryName: T, latitude: D, longitude: D, fromCoord: T, toCoord: T) => {
    const query = queryName

    const latitudeQuery = latitude ? '&lat='.concat(String(latitude)) : ''
    const longitudeQuery = longitude ? '&lon='.concat(String(longitude)) : ''
    const fromCoordQuery = fromCoord ? '&fromCoord='.concat(fromCoord) : ''
    const toCoordQuery = toCoord ? '&toCoord='.concat(toCoord) : ''

    return query.concat(latitudeQuery, longitudeQuery, fromCoordQuery, toCoordQuery)
  }
  return {
    skGeoQuery,
  }
}
