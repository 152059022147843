import { useKakao } from 'vue3-kakao-maps/@utils'

export default defineNuxtPlugin((_nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const kakaoAppKey = runtimeConfig.public.kakaoJavascriptApiKey

  if (import.meta.client) {
    useKakao(kakaoAppKey)
  }
})
