import type { ChatChannelDatabase } from '@/types/supabaseChatChannel'

export const useLoadMeetingData = () => {
  const user = useSupabaseUser()
  const {
    newOwnerExpertMessage,
    newOwnerHostMessage,
    newCoachingExpertMessage,
    newCoachingHostMessage,
    newRepairExpertMessage,
    newRepairHostMessage,
    newRescueExpertMessage,
    newRescueHostMessage,
    newPickupExpertMessage,
    newPickupHostMessage,
  } = storeToRefs(useNotificationDataStore())

  const { schemaFetchOptionData } = useFetchComposable()

  const { execute: executeOwnerExpertMeetingData } = useAsyncData('layoutOwnerExpertMeetingData', async () => {
    if (!user.value) {
      return []
    }

    const result = await schemaFetchOptionData('chat_channel', 'ownerMeeting', '*', 'expert_user_id', user.value?.id ?? '')

    const findMessage = result.find((item: ChatChannelDatabase['chat_channel']['Tables']['ownerMeeting']['Row']) => item.new_host_message)

    findMessage
      ? newOwnerHostMessage.value = true
      : newOwnerHostMessage.value = false

    return result ?? []
  }, {
    immediate: true,
  })

  const { execute: executeOwnerHostMeetingData } = useAsyncData('layoutOwnerHostMeetingData', async () => {
    if (!user.value) {
      return []
    }

    const result = await schemaFetchOptionData('chat_channel', 'ownerMeeting', '*', 'host_user_id', user.value?.id ?? '')

    const findMessage = result.find((item: ChatChannelDatabase['chat_channel']['Tables']['ownerMeeting']['Row']) => item.new_expert_message)

    findMessage
      ? newOwnerExpertMessage.value = true
      : newOwnerExpertMessage.value = false

    return result ?? []
  }, {
    immediate: true,
  })

  const { execute: executeCoachingExpertMeetingData } = useAsyncData('layoutCoachingExpertMeetingData', async () => {
    if (!user.value) {
      return []
    }

    const result = await schemaFetchOptionData('chat_channel', 'coachingMeeting', '*', 'expert_user_id', user.value?.id ?? '')

    const findMessage = result.find((item: ChatChannelDatabase['chat_channel']['Tables']['coachingMeeting']['Row']) => item.new_host_message)

    findMessage
      ? newCoachingHostMessage.value = true
      : newCoachingHostMessage.value = false

    return result ?? []
  }, {
    immediate: true,
  })

  const { execute: executeCoachingHostMeetingData } = useAsyncData('layoutCoachingHostMeetingData', async () => {
    if (!user.value) {
      return []
    }

    const result = await schemaFetchOptionData('chat_channel', 'coachingMeeting', '*', 'host_user_id', user.value?.id ?? '')

    const findMessage = result.find((item: ChatChannelDatabase['chat_channel']['Tables']['coachingMeeting']['Row']) => item.new_expert_message)

    findMessage
      ? newCoachingExpertMessage.value = true
      : newCoachingExpertMessage.value = false

    return result ?? []
  }, {
    immediate: true,
  })

  const { execute: executeRepairExpertMeetingData } = useAsyncData('layoutRepairExpertMeetingData', async () => {
    if (!user.value) {
      return []
    }

    const result = await schemaFetchOptionData('chat_channel', 'repairMeeting', '*', 'expert_user_id', user.value?.id ?? '')

    const findMessage = result.find((item: ChatChannelDatabase['chat_channel']['Tables']['repairMeeting']['Row']) => item.new_host_message)

    findMessage
      ? newRepairHostMessage.value = true
      : newRepairHostMessage.value = false

    return result ?? []
  }, {
    immediate: true,
  })

  const { execute: executeRepairHostMeetingData } = useAsyncData('layoutRepairHostMeetingData', async () => {
    if (!user.value) {
      return []
    }

    const result = await schemaFetchOptionData('chat_channel', 'repairMeeting', '*', 'host_user_id', user.value?.id ?? '')

    const findMessage = result.find((item: ChatChannelDatabase['chat_channel']['Tables']['repairMeeting']['Row']) => item.new_expert_message)

    findMessage
      ? newRepairExpertMessage.value = true
      : newRepairExpertMessage.value = false

    return result ?? []
  }, {
    immediate: true,
  })

  const { execute: executeRescueExpertMeetingData } = useAsyncData('layoutRescueExpertMeetingData', async () => {
    if (!user.value) {
      return []
    }

    const result = await schemaFetchOptionData('chat_channel', 'rescueMeeting', '*', 'expert_user_id', user.value?.id ?? '')

    const findMessage = result.find((item: ChatChannelDatabase['chat_channel']['Tables']['rescueMeeting']['Row']) => item.new_host_message)

    findMessage
      ? newRescueHostMessage.value = true
      : newRescueHostMessage.value = false

    return result ?? []
  }, {
    immediate: true,
  })

  const { execute: executeRescueHostMeetingData } = useAsyncData('layoutRescueHostMeetingData', async () => {
    if (!user.value) {
      return []
    }

    const result = await schemaFetchOptionData('chat_channel', 'rescueMeeting', '*', 'host_user_id', user.value?.id ?? '')

    const findMessage = result.find((item: ChatChannelDatabase['chat_channel']['Tables']['rescueMeeting']['Row']) => item.new_expert_message)

    findMessage
      ? newRescueExpertMessage.value = true
      : newRescueExpertMessage.value = false

    return result ?? []
  }, {
    immediate: true,
  })

  const { execute: executePickupExpertMeetingData } = useAsyncData('layoutPickupExpertMeetingData', async () => {
    if (!user.value) {
      return []
    }

    const result = await schemaFetchOptionData('chat_channel', 'pickupMeeting', '*', 'expert_user_id', user.value?.id ?? '')

    const findMessage = result.find((item: ChatChannelDatabase['chat_channel']['Tables']['pickupMeeting']['Row']) => item.new_host_message)

    findMessage
      ? newPickupHostMessage.value = true
      : newPickupHostMessage.value = false

    return result ?? []
  }, {
    immediate: true,
  })

  const { execute: executePickupHostMeetingData } = useAsyncData('layoutPickupHostMeetingData', async () => {
    if (!user.value) {
      return []
    }

    const result = await schemaFetchOptionData('chat_channel', 'pickupMeeting', '*', 'host_user_id', user.value?.id ?? '')

    const findMessage = result.find((item: ChatChannelDatabase['chat_channel']['Tables']['pickupMeeting']['Row']) => item.new_expert_message)

    findMessage
      ? newPickupExpertMessage.value = true
      : newPickupExpertMessage.value = false

    return result ?? []
  }, {
    immediate: true,
  })

  const executeMetingData = () => {
    executeOwnerExpertMeetingData()
    executeOwnerHostMeetingData()
    executeCoachingExpertMeetingData()
    executeCoachingHostMeetingData()
    executeRepairExpertMeetingData()
    executeRepairHostMeetingData()
    executeRescueExpertMeetingData()
    executeRescueHostMeetingData()
    executePickupExpertMeetingData()
    executePickupHostMeetingData()
  }

  return {
    executeMetingData,
  }
}
