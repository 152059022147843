import { defineStore } from 'pinia'
import type { FilterDatabase } from '@/types/supabaseFilter'

export const useFilterDataStore = defineStore('filterDataStore', () => {
  /**
   * ! Pinia State !
   *
   * @param meetingTypeData 미팅 타입
   * @param meetingStatusData 미팅 상태
   *
   * @param filterPointList 포인트 코드 목록
   * @param filterMotoGenreData 모터사이클 장르
   * @param filterBusinessHourData 사업장 영업시간
   * @param filterBusinessType 사업장 업태
   * @param filterPickupItemData 픽업 아이템
   * @param filterPickupSkillData 픽업 스킬
   * @param filterCoupon 쿠폰
   *
   */

  const meetingTypeData = ref<FilterDatabase['filter']['Tables']['meetingType']['Row'][]>()
  const meetingStatusData = ref<FilterDatabase['filter']['Tables']['meetingStatus']['Row'][]>()

  const filterPointList = ref<FilterDatabase['filter']['Tables']['point']['Row'][]>()
  const filterMotoGenreData = ref<FilterDatabase['filter']['Tables']['motoGenre']['Row'][]>()
  const filterBusinessType = ref<FilterDatabase['filter']['Tables']['businessType']['Row'][]>()
  const filterBusinessHourData = ref<FilterDatabase['filter']['Tables']['businessHours']['Row'][]>()
  const filterPickupItemData = ref<FilterDatabase['filter']['Tables']['pickupItem']['Row'][]>()
  const filterPickupSkillData = ref<FilterDatabase['filter']['Tables']['pickupSkill']['Row'][]>()
  const filterCoupon = ref<(FilterDatabase['filter']['Views']['viewFilterCoupon']['Row'] & { businessType: FilterDatabase['filter']['Tables']['businessType']['Row'] })[]>()

  return {
    meetingTypeData,
    meetingStatusData,
    filterPointList,
    filterMotoGenreData,
    filterBusinessType,
    filterBusinessHourData,
    filterPickupItemData,
    filterPickupSkillData,
    filterCoupon,
  }
}, {
  persist: {
    storage: persistedState.localStorage,
  },
})
