import { default as announceBH7NBKW1jvMeta } from "/vercel/path0/pages/announce.vue?macro=true";
import { default as businessgmAikDIzzBMeta } from "/vercel/path0/pages/board/business.vue?macro=true";
import { default as freeZJ0jhrtGaZMeta } from "/vercel/path0/pages/board/free.vue?macro=true";
import { default as galleryLNW67R0CioMeta } from "/vercel/path0/pages/board/gallery.vue?macro=true";
import { default as _91boardId_93tPpCZ2cbxXMeta } from "/vercel/path0/pages/board/guestbook/[boardId].vue?macro=true";
import { default as indexz1yQ33v7KBMeta } from "/vercel/path0/pages/board/guestbook/index.vue?macro=true";
import { default as writegCG1iuGk4PMeta } from "/vercel/path0/pages/board/guestbook/write.vue?macro=true";
import { default as issueJ3nxECW0vHMeta } from "/vercel/path0/pages/board/issue.vue?macro=true";
import { default as qnaQrNd8p2sLLMeta } from "/vercel/path0/pages/board/qna.vue?macro=true";
import { default as reportTheftOiTG8O7PF1Meta } from "/vercel/path0/pages/board/reportTheft.vue?macro=true";
import { default as ridingOGjw2SJAHLMeta } from "/vercel/path0/pages/board/riding.vue?macro=true";
import { default as tuning50N41sTjysMeta } from "/vercel/path0/pages/board/tuning.vue?macro=true";
import { default as vehicleCare1s8GTsUFHqMeta } from "/vercel/path0/pages/board/vehicleCare.vue?macro=true";
import { default as confirm2uAIxw9fKZMeta } from "/vercel/path0/pages/confirm.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexoOBDY93e9EMeta } from "/vercel/path0/pages/login/index.vue?macro=true";
import { default as naverL2J3UqldP3Meta } from "/vercel/path0/pages/login/naver.vue?macro=true";
import { default as newfEOIiDZ51GMeta } from "/vercel/path0/pages/login/new.vue?macro=true";
import { default as welcomeXFKkW4B3tOMeta } from "/vercel/path0/pages/login/welcome.vue?macro=true";
import { default as withdrawaluIUz3DJFjlMeta } from "/vercel/path0/pages/login/withdrawal.vue?macro=true";
import { default as buyS4lumNf2rcMeta } from "/vercel/path0/pages/market/buy.vue?macro=true";
import { default as deliveryGearLMIQ2XheSlMeta } from "/vercel/path0/pages/market/deliveryGear.vue?macro=true";
import { default as gearKbrEqoa7tCMeta } from "/vercel/path0/pages/market/gear.vue?macro=true";
import { default as manualbkrE4J7MH4Meta } from "/vercel/path0/pages/market/over125/manual.vue?macro=true";
import { default as scooterJIphK7ihf3Meta } from "/vercel/path0/pages/market/over125/scooter.vue?macro=true";
import { default as partsyM0cCSWzHDMeta } from "/vercel/path0/pages/market/parts.vue?macro=true";
import { default as manualPDwoXiKehyMeta } from "/vercel/path0/pages/market/under125/manual.vue?macro=true";
import { default as scooter4RxoW4bIbFMeta } from "/vercel/path0/pages/market/under125/scooter.vue?macro=true";
import { default as chat6yquTnr4JaMeta } from "/vercel/path0/pages/meeting/coaching/chat.vue?macro=true";
import { default as indexP8dK97GLuPMeta } from "/vercel/path0/pages/meeting/coaching/index.vue?macro=true";
import { default as searchX4z6bkkpFnMeta } from "/vercel/path0/pages/meeting/coaching/search.vue?macro=true";
import { default as feedbackuEO4omixEWMeta } from "/vercel/path0/pages/meeting/feedback.vue?macro=true";
import { default as chatkYC5HtyZQLMeta } from "/vercel/path0/pages/meeting/owner/chat.vue?macro=true";
import { default as indexCTCKT4SMw2Meta } from "/vercel/path0/pages/meeting/owner/index.vue?macro=true";
import { default as searchFsagdMY29gMeta } from "/vercel/path0/pages/meeting/owner/search.vue?macro=true";
import { default as chatMyaYDPSiuhMeta } from "/vercel/path0/pages/meeting/pickup/chat.vue?macro=true";
import { default as index4DJddCeFO9Meta } from "/vercel/path0/pages/meeting/pickup/index.vue?macro=true";
import { default as searchqLD9uWdcxfMeta } from "/vercel/path0/pages/meeting/pickup/search.vue?macro=true";
import { default as chatAoVDUoKiTjMeta } from "/vercel/path0/pages/meeting/repair/chat.vue?macro=true";
import { default as indexCCOG9PC5XJMeta } from "/vercel/path0/pages/meeting/repair/index.vue?macro=true";
import { default as searchdVyRbngRiBMeta } from "/vercel/path0/pages/meeting/repair/search.vue?macro=true";
import { default as chatv0qszGZsYCMeta } from "/vercel/path0/pages/meeting/rescue/chat.vue?macro=true";
import { default as indexxNpEcTnNieMeta } from "/vercel/path0/pages/meeting/rescue/index.vue?macro=true";
import { default as searchK8o0dcnPmCMeta } from "/vercel/path0/pages/meeting/rescue/search.vue?macro=true";
import { default as boardQKNegkj13WMeta } from "/vercel/path0/pages/my/board.vue?macro=true";
import { default as chat67iHbMccJMMeta } from "/vercel/path0/pages/my/chat.vue?macro=true";
import { default as coachingTtgKsF0Ck7Meta } from "/vercel/path0/pages/my/coaching.vue?macro=true";
import { default as couponXU7chmpesfMeta } from "/vercel/path0/pages/my/coupon.vue?macro=true";
import { default as keepLSSxRlFwPtMeta } from "/vercel/path0/pages/my/keep.vue?macro=true";
import { default as marketiEHQic5HgzMeta } from "/vercel/path0/pages/my/market.vue?macro=true";
import { default as owner1stIltMnpwMeta } from "/vercel/path0/pages/my/owner.vue?macro=true";
import { default as pickupoZLdCzXi1EMeta } from "/vercel/path0/pages/my/pickup.vue?macro=true";
import { default as pintiF2aZxUWFMeta } from "/vercel/path0/pages/my/pin.vue?macro=true";
import { default as pointRQRTOh63KnMeta } from "/vercel/path0/pages/my/point.vue?macro=true";
import { default as repaircQCQs0lvinMeta } from "/vercel/path0/pages/my/repair.vue?macro=true";
import { default as repairSkill7TGRfj1IgXMeta } from "/vercel/path0/pages/my/repairSkill.vue?macro=true";
import { default as rescueATV81qknphMeta } from "/vercel/path0/pages/my/rescue.vue?macro=true";
import { default as reviewSXOfnxCu0SMeta } from "/vercel/path0/pages/my/review.vue?macro=true";
import { default as shop4YM4EX21epMeta } from "/vercel/path0/pages/my/shop.vue?macro=true";
import { default as photographerGGLACQZsupMeta } from "/vercel/path0/pages/photographer.vue?macro=true";
import { default as failRQDP35LDA2Meta } from "/vercel/path0/pages/point/charge/fail.vue?macro=true";
import { default as index_46clientD988EXs89kMeta } from "/vercel/path0/pages/point/charge/index.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/vercel/path0/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as successM8h4B9ldEjMeta } from "/vercel/path0/pages/point/charge/success.vue?macro=true";
import { default as exchange8O94DQKERgMeta } from "/vercel/path0/pages/point/exchange.vue?macro=true";
import { default as policyNKohT57BqpMeta } from "/vercel/path0/pages/policy.vue?macro=true";
import { default as _91id_93FDV9aaSSaFMeta } from "/vercel/path0/pages/profile/[id].vue?macro=true";
import { default as indexSsrwCXeuTQMeta } from "/vercel/path0/pages/profile/index.vue?macro=true";
import { default as indexftjGK2FLAwMeta } from "/vercel/path0/pages/report/motorcycle/index.vue?macro=true";
import { default as indexe7OizcFKr4Meta } from "/vercel/path0/pages/review/gear/index.vue?macro=true";
import { default as indexHxa16JqNIHMeta } from "/vercel/path0/pages/review/motorcycle/index.vue?macro=true";
import { default as lista1EowrQeioMeta } from "/vercel/path0/pages/review/motorcycle/list.vue?macro=true";
import { default as index0YrmoYIrhWMeta } from "/vercel/path0/pages/review/motorcycle/my/index.vue?macro=true";
import { default as indexpmpJC424D3Meta } from "/vercel/path0/pages/review/motorcycle/search/index.vue?macro=true";
import { default as resultwNB5qWDY1mMeta } from "/vercel/path0/pages/review/motorcycle/search/result.vue?macro=true";
import { default as confirmaSlWTOBqOFMeta } from "/vercel/path0/pages/review/motorcycle/write/confirm.vue?macro=true";
import { default as index4h8Z3bqcfdMeta } from "/vercel/path0/pages/review/motorcycle/write/index.vue?macro=true";
import { default as initSonDV8mg06Meta } from "/vercel/path0/pages/review/motorcycle/write/init.vue?macro=true";
import { default as search4N3qS51VkgMeta } from "/vercel/path0/pages/review/motorcycle/write/search.vue?macro=true";
import { default as blindeTK1umDDBVMeta } from "/vercel/path0/pages/setting/blind.vue?macro=true";
import { default as indexlj5aTopGWCMeta } from "/vercel/path0/pages/setting/index.vue?macro=true";
import { default as resultbyvPGhATCAMeta } from "/vercel/path0/pages/specCompare/result.vue?macro=true";
import { default as searchjvFp7iT6hDMeta } from "/vercel/path0/pages/specCompare/search.vue?macro=true";
import { default as selectq6KnhDY9OAMeta } from "/vercel/path0/pages/specCompare/select.vue?macro=true";
export default [
  {
    name: "announce",
    path: "/announce",
    component: () => import("/vercel/path0/pages/announce.vue")
  },
  {
    name: "board-business",
    path: "/board/business",
    component: () => import("/vercel/path0/pages/board/business.vue")
  },
  {
    name: "board-free",
    path: "/board/free",
    component: () => import("/vercel/path0/pages/board/free.vue")
  },
  {
    name: "board-gallery",
    path: "/board/gallery",
    component: () => import("/vercel/path0/pages/board/gallery.vue")
  },
  {
    name: "board-guestbook-boardId",
    path: "/board/guestbook/:boardId()",
    meta: _91boardId_93tPpCZ2cbxXMeta || {},
    component: () => import("/vercel/path0/pages/board/guestbook/[boardId].vue")
  },
  {
    name: "board-guestbook",
    path: "/board/guestbook",
    meta: indexz1yQ33v7KBMeta || {},
    component: () => import("/vercel/path0/pages/board/guestbook/index.vue")
  },
  {
    name: "board-guestbook-write",
    path: "/board/guestbook/write",
    meta: writegCG1iuGk4PMeta || {},
    component: () => import("/vercel/path0/pages/board/guestbook/write.vue")
  },
  {
    name: "board-issue",
    path: "/board/issue",
    component: () => import("/vercel/path0/pages/board/issue.vue")
  },
  {
    name: "board-qna",
    path: "/board/qna",
    component: () => import("/vercel/path0/pages/board/qna.vue")
  },
  {
    name: "board-reportTheft",
    path: "/board/reportTheft",
    component: () => import("/vercel/path0/pages/board/reportTheft.vue")
  },
  {
    name: "board-riding",
    path: "/board/riding",
    component: () => import("/vercel/path0/pages/board/riding.vue")
  },
  {
    name: "board-tuning",
    path: "/board/tuning",
    component: () => import("/vercel/path0/pages/board/tuning.vue")
  },
  {
    name: "board-vehicleCare",
    path: "/board/vehicleCare",
    component: () => import("/vercel/path0/pages/board/vehicleCare.vue")
  },
  {
    name: "confirm",
    path: "/confirm",
    meta: confirm2uAIxw9fKZMeta || {},
    component: () => import("/vercel/path0/pages/confirm.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/vercel/path0/pages/login/index.vue")
  },
  {
    name: "login-naver",
    path: "/login/naver",
    component: () => import("/vercel/path0/pages/login/naver.vue")
  },
  {
    name: "login-new",
    path: "/login/new",
    meta: newfEOIiDZ51GMeta || {},
    component: () => import("/vercel/path0/pages/login/new.vue")
  },
  {
    name: "login-welcome",
    path: "/login/welcome",
    meta: welcomeXFKkW4B3tOMeta || {},
    component: () => import("/vercel/path0/pages/login/welcome.vue")
  },
  {
    name: "login-withdrawal",
    path: "/login/withdrawal",
    component: () => import("/vercel/path0/pages/login/withdrawal.vue")
  },
  {
    name: "market-buy",
    path: "/market/buy",
    component: () => import("/vercel/path0/pages/market/buy.vue")
  },
  {
    name: "market-deliveryGear",
    path: "/market/deliveryGear",
    component: () => import("/vercel/path0/pages/market/deliveryGear.vue")
  },
  {
    name: "market-gear",
    path: "/market/gear",
    component: () => import("/vercel/path0/pages/market/gear.vue")
  },
  {
    name: "market-over125-manual",
    path: "/market/over125/manual",
    component: () => import("/vercel/path0/pages/market/over125/manual.vue")
  },
  {
    name: "market-over125-scooter",
    path: "/market/over125/scooter",
    component: () => import("/vercel/path0/pages/market/over125/scooter.vue")
  },
  {
    name: "market-parts",
    path: "/market/parts",
    component: () => import("/vercel/path0/pages/market/parts.vue")
  },
  {
    name: "market-under125-manual",
    path: "/market/under125/manual",
    component: () => import("/vercel/path0/pages/market/under125/manual.vue")
  },
  {
    name: "market-under125-scooter",
    path: "/market/under125/scooter",
    component: () => import("/vercel/path0/pages/market/under125/scooter.vue")
  },
  {
    name: "meeting-coaching-chat",
    path: "/meeting/coaching/chat",
    meta: chat6yquTnr4JaMeta || {},
    component: () => import("/vercel/path0/pages/meeting/coaching/chat.vue")
  },
  {
    name: "meeting-coaching",
    path: "/meeting/coaching",
    meta: indexP8dK97GLuPMeta || {},
    component: () => import("/vercel/path0/pages/meeting/coaching/index.vue")
  },
  {
    name: "meeting-coaching-search",
    path: "/meeting/coaching/search",
    meta: searchX4z6bkkpFnMeta || {},
    component: () => import("/vercel/path0/pages/meeting/coaching/search.vue")
  },
  {
    name: "meeting-feedback",
    path: "/meeting/feedback",
    meta: feedbackuEO4omixEWMeta || {},
    component: () => import("/vercel/path0/pages/meeting/feedback.vue")
  },
  {
    name: "meeting-owner-chat",
    path: "/meeting/owner/chat",
    meta: chatkYC5HtyZQLMeta || {},
    component: () => import("/vercel/path0/pages/meeting/owner/chat.vue")
  },
  {
    name: "meeting-owner",
    path: "/meeting/owner",
    meta: indexCTCKT4SMw2Meta || {},
    component: () => import("/vercel/path0/pages/meeting/owner/index.vue")
  },
  {
    name: "meeting-owner-search",
    path: "/meeting/owner/search",
    meta: searchFsagdMY29gMeta || {},
    component: () => import("/vercel/path0/pages/meeting/owner/search.vue")
  },
  {
    name: "meeting-pickup-chat",
    path: "/meeting/pickup/chat",
    meta: chatMyaYDPSiuhMeta || {},
    component: () => import("/vercel/path0/pages/meeting/pickup/chat.vue")
  },
  {
    name: "meeting-pickup",
    path: "/meeting/pickup",
    meta: index4DJddCeFO9Meta || {},
    component: () => import("/vercel/path0/pages/meeting/pickup/index.vue")
  },
  {
    name: "meeting-pickup-search",
    path: "/meeting/pickup/search",
    meta: searchqLD9uWdcxfMeta || {},
    component: () => import("/vercel/path0/pages/meeting/pickup/search.vue")
  },
  {
    name: "meeting-repair-chat",
    path: "/meeting/repair/chat",
    meta: chatAoVDUoKiTjMeta || {},
    component: () => import("/vercel/path0/pages/meeting/repair/chat.vue")
  },
  {
    name: "meeting-repair",
    path: "/meeting/repair",
    meta: indexCCOG9PC5XJMeta || {},
    component: () => import("/vercel/path0/pages/meeting/repair/index.vue")
  },
  {
    name: "meeting-repair-search",
    path: "/meeting/repair/search",
    meta: searchdVyRbngRiBMeta || {},
    component: () => import("/vercel/path0/pages/meeting/repair/search.vue")
  },
  {
    name: "meeting-rescue-chat",
    path: "/meeting/rescue/chat",
    meta: chatv0qszGZsYCMeta || {},
    component: () => import("/vercel/path0/pages/meeting/rescue/chat.vue")
  },
  {
    name: "meeting-rescue",
    path: "/meeting/rescue",
    meta: indexxNpEcTnNieMeta || {},
    component: () => import("/vercel/path0/pages/meeting/rescue/index.vue")
  },
  {
    name: "meeting-rescue-search",
    path: "/meeting/rescue/search",
    meta: searchK8o0dcnPmCMeta || {},
    component: () => import("/vercel/path0/pages/meeting/rescue/search.vue")
  },
  {
    name: "my-board",
    path: "/my/board",
    component: () => import("/vercel/path0/pages/my/board.vue")
  },
  {
    name: "my-chat",
    path: "/my/chat",
    component: () => import("/vercel/path0/pages/my/chat.vue")
  },
  {
    name: "my-coaching",
    path: "/my/coaching",
    meta: coachingTtgKsF0Ck7Meta || {},
    component: () => import("/vercel/path0/pages/my/coaching.vue")
  },
  {
    name: "my-coupon",
    path: "/my/coupon",
    meta: couponXU7chmpesfMeta || {},
    component: () => import("/vercel/path0/pages/my/coupon.vue")
  },
  {
    name: "my-keep",
    path: "/my/keep",
    meta: keepLSSxRlFwPtMeta || {},
    component: () => import("/vercel/path0/pages/my/keep.vue")
  },
  {
    name: "my-market",
    path: "/my/market",
    component: () => import("/vercel/path0/pages/my/market.vue")
  },
  {
    name: "my-owner",
    path: "/my/owner",
    meta: owner1stIltMnpwMeta || {},
    component: () => import("/vercel/path0/pages/my/owner.vue")
  },
  {
    name: "my-pickup",
    path: "/my/pickup",
    meta: pickupoZLdCzXi1EMeta || {},
    component: () => import("/vercel/path0/pages/my/pickup.vue")
  },
  {
    name: "my-pin",
    path: "/my/pin",
    meta: pintiF2aZxUWFMeta || {},
    component: () => import("/vercel/path0/pages/my/pin.vue")
  },
  {
    name: "my-point",
    path: "/my/point",
    meta: pointRQRTOh63KnMeta || {},
    component: () => import("/vercel/path0/pages/my/point.vue")
  },
  {
    name: "my-repair",
    path: "/my/repair",
    meta: repaircQCQs0lvinMeta || {},
    component: () => import("/vercel/path0/pages/my/repair.vue")
  },
  {
    name: "my-repairSkill",
    path: "/my/repairSkill",
    meta: repairSkill7TGRfj1IgXMeta || {},
    component: () => import("/vercel/path0/pages/my/repairSkill.vue")
  },
  {
    name: "my-rescue",
    path: "/my/rescue",
    meta: rescueATV81qknphMeta || {},
    component: () => import("/vercel/path0/pages/my/rescue.vue")
  },
  {
    name: "my-review",
    path: "/my/review",
    meta: reviewSXOfnxCu0SMeta || {},
    component: () => import("/vercel/path0/pages/my/review.vue")
  },
  {
    name: "my-shop",
    path: "/my/shop",
    meta: shop4YM4EX21epMeta || {},
    component: () => import("/vercel/path0/pages/my/shop.vue")
  },
  {
    name: "photographer",
    path: "/photographer",
    component: () => import("/vercel/path0/pages/photographer.vue")
  },
  {
    name: "point-charge-fail",
    path: "/point/charge/fail",
    meta: failRQDP35LDA2Meta || {},
    component: () => import("/vercel/path0/pages/point/charge/fail.vue")
  },
  {
    name: "point-charge",
    path: "/point/charge",
    meta: index_46clientD988EXs89kMeta || {},
    component: () => createClientPage(() => import("/vercel/path0/pages/point/charge/index.client.vue"))
  },
  {
    name: "point-charge-success",
    path: "/point/charge/success",
    meta: successM8h4B9ldEjMeta || {},
    component: () => import("/vercel/path0/pages/point/charge/success.vue")
  },
  {
    name: "point-exchange",
    path: "/point/exchange",
    component: () => import("/vercel/path0/pages/point/exchange.vue")
  },
  {
    name: "policy",
    path: "/policy",
    component: () => import("/vercel/path0/pages/policy.vue")
  },
  {
    name: "profile-id",
    path: "/profile/:id()",
    meta: _91id_93FDV9aaSSaFMeta || {},
    component: () => import("/vercel/path0/pages/profile/[id].vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexSsrwCXeuTQMeta || {},
    component: () => import("/vercel/path0/pages/profile/index.vue")
  },
  {
    name: "report-motorcycle",
    path: "/report/motorcycle",
    meta: indexftjGK2FLAwMeta || {},
    component: () => import("/vercel/path0/pages/report/motorcycle/index.vue")
  },
  {
    name: "review-gear",
    path: "/review/gear",
    component: () => import("/vercel/path0/pages/review/gear/index.vue")
  },
  {
    name: "review-motorcycle",
    path: "/review/motorcycle",
    component: () => import("/vercel/path0/pages/review/motorcycle/index.vue")
  },
  {
    name: "review-motorcycle-list",
    path: "/review/motorcycle/list",
    component: () => import("/vercel/path0/pages/review/motorcycle/list.vue")
  },
  {
    name: "review-motorcycle-my",
    path: "/review/motorcycle/my",
    meta: index0YrmoYIrhWMeta || {},
    component: () => import("/vercel/path0/pages/review/motorcycle/my/index.vue")
  },
  {
    name: "review-motorcycle-search",
    path: "/review/motorcycle/search",
    component: () => import("/vercel/path0/pages/review/motorcycle/search/index.vue")
  },
  {
    name: "review-motorcycle-search-result",
    path: "/review/motorcycle/search/result",
    component: () => import("/vercel/path0/pages/review/motorcycle/search/result.vue")
  },
  {
    name: "review-motorcycle-write-confirm",
    path: "/review/motorcycle/write/confirm",
    meta: confirmaSlWTOBqOFMeta || {},
    component: () => import("/vercel/path0/pages/review/motorcycle/write/confirm.vue")
  },
  {
    name: "review-motorcycle-write",
    path: "/review/motorcycle/write",
    meta: index4h8Z3bqcfdMeta || {},
    component: () => import("/vercel/path0/pages/review/motorcycle/write/index.vue")
  },
  {
    name: "review-motorcycle-write-init",
    path: "/review/motorcycle/write/init",
    component: () => import("/vercel/path0/pages/review/motorcycle/write/init.vue")
  },
  {
    name: "review-motorcycle-write-search",
    path: "/review/motorcycle/write/search",
    meta: search4N3qS51VkgMeta || {},
    component: () => import("/vercel/path0/pages/review/motorcycle/write/search.vue")
  },
  {
    name: "setting-blind",
    path: "/setting/blind",
    meta: blindeTK1umDDBVMeta || {},
    component: () => import("/vercel/path0/pages/setting/blind.vue")
  },
  {
    name: "setting",
    path: "/setting",
    component: () => import("/vercel/path0/pages/setting/index.vue")
  },
  {
    name: "specCompare-result",
    path: "/specCompare/result",
    component: () => import("/vercel/path0/pages/specCompare/result.vue")
  },
  {
    name: "specCompare-search",
    path: "/specCompare/search",
    component: () => import("/vercel/path0/pages/specCompare/search.vue")
  },
  {
    name: "specCompare-select",
    path: "/specCompare/select",
    component: () => import("/vercel/path0/pages/specCompare/select.vue")
  }
]