import { defineStore } from 'pinia'

export const useNotificationDataStore = defineStore('notificationDataStore', () => {
  /**
   * ! Pinia State !
   *
   * @param integratedNotification 통합 알림
   * @param newOwnerExpertMessage 오너 전문가 새로운 메시지
   * @param newOwnerHostMessage 오너 호스트 새로운 메시지
   * @param newCoachingExpertMessage 주행 코칭 전문가 새로운 메시지
   * @param newCoachingHostMessage 주행 코칭 호스트 새로운 메시지
   * @param newRepairExpertMessage 정비 전문가 새로운 메시지
   * @param newRepairHostMessage 정비 호스트 새로운 메시지
   * @param newRescueExpertMessage 구난 전문가 새로운 메시지
   * @param newRescueHostMessage 구난 호스트 새로운 메시지
   * @param newPickupExpertMessage 픽업 전문가 새로운 메시지
   * @param newPickupHostMessage 픽업 호스트 새로운 메시지
   *
   */

  const integratedNotification = ref(false)

  const newOwnerExpertMessage = ref(false)
  const newOwnerHostMessage = ref(false)
  const newCoachingExpertMessage = ref(false)
  const newCoachingHostMessage = ref(false)
  const newRepairExpertMessage = ref(false)
  const newRepairHostMessage = ref(false)
  const newPickupExpertMessage = ref(false)
  const newPickupHostMessage = ref(false)
  const newRescueExpertMessage = ref(false)
  const newRescueHostMessage = ref(false)

  watch([newOwnerExpertMessage, newOwnerHostMessage, newCoachingExpertMessage, newCoachingHostMessage, newRepairExpertMessage, newRepairHostMessage, newPickupExpertMessage, newPickupHostMessage, newRescueExpertMessage, newRescueHostMessage], () => {
    integratedNotification.value = newOwnerExpertMessage.value
    || newOwnerHostMessage.value
    || newCoachingExpertMessage.value
    || newCoachingHostMessage.value
    || newRepairExpertMessage.value
    || newRepairHostMessage.value
    || newPickupExpertMessage.value
    || newPickupHostMessage.value
    || newRescueExpertMessage.value
    || newRescueHostMessage.value
  })

  return {
    integratedNotification,
    newOwnerExpertMessage,
    newOwnerHostMessage,
    newCoachingExpertMessage,
    newCoachingHostMessage,
    newRepairExpertMessage,
    newRepairHostMessage,
    newPickupExpertMessage,
    newPickupHostMessage,
    newRescueExpertMessage,
    newRescueHostMessage,
  }
}, {
  persist: {
    storage: persistedState.sessionStorage,
  },
})
